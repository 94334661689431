import React from 'react';
import { Paper, Typography, Grid, Link, Divider, List, ListItem, ListItemText, Box } from '@mui/material';

import resumeData from './resumeData';

const ResumeView = () => {
    const { skills, workExperience, openSourceProjects, classList } = resumeData;

    const classList1 = classList.slice(0, classList.length / 2);
    const classList2 = classList.slice(classList.length / 2);

    return (
        <div id="resume-view" className="section">
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <Paper sx={{ maxWidth: 960, width: '100%', p: 3, bgcolor: 'background.paper' }}>
                    <Typography variant="h5" align="center" gutterBottom><strong>Resume</strong></Typography>
                    <Typography variant="subtitle1" align="center" gutterBottom>
                        Joshua Caponigro • Chapel Hill, North Carolina • <Link href="mailto:jcaponigro20@gmail.com" underline="hover">jcaponigro20@gmail.com</Link>
                    </Typography>

                    <Divider sx={{ my: 3 }} />

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <Typography variant="h6" gutterBottom>Education</Typography>
                                <List>
                                    <ListItem disablePadding>
                                        <ListItemText
                                            primary="UNIVERSITY OF NORTH CAROLINA AT CHAPEL HILL"
                                            secondary={
                                                <React.Fragment>
                                                    <Typography component="span" variant="body2">
                                                        B.A. in Computer Science and Economics (August 2020 - May 2024)
                                                    </Typography>
                                                    <Typography component="span" variant="body2" display="block">
                                                        Minor in Philosophy, Politics, and Economics (PPE)
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <Typography variant="h6" gutterBottom>Technical Skills</Typography>
                                {Object.entries(skills).map(([key, value]) => (
                                    <Typography variant="body2" align="left" key={key}>
                                        {key.charAt(0).toUpperCase() + key.slice(1)}: {value.join(", ")}
                                    </Typography>
                                ))}
                            </Box>
                        </Grid>

                    </Grid>
                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h6" gutterBottom>Work Experience</Typography>
                    <List sx={{ width: '100%' }}>
                        {workExperience.map((job, index) => (
                            <ListItem key={index} alignItems="flex-start" sx={{ flexDirection: 'column', alignItems: 'start' }}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item xs={8}>
                                        <Typography variant="subtitle1">{job.title}</Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                                        <Typography variant="subtitle2">{`${job.location} (${job.date})`}</Typography>
                                    </Grid>
                                </Grid>
                                {job.descriptions.map((desc, i) => (
                                    <Typography key={i} variant="body2">
                                        — {desc}
                                    </Typography>
                                ))}
                            </ListItem>
                        ))}
                    </List>

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h6" gutterBottom>Open Source Projects</Typography>
                    <List sx={{ width: '100%' }}>
                        {openSourceProjects.map((project, index) => (
                            <ListItem key={index} alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <Link href={project.link} target="_blank" underline="hover">
                                            {project.name}
                                        </Link>}
                                    secondary={project.description}
                                />
                            </ListItem>
                        ))}
                    </List>

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h6" gutterBottom>Relevant Coursework</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <List sx={{ padding: 0 }}>
                                {classList1.map(course => (
                                    <ListItem key={course} alignItems="flex-start" sx={{ py: 0.5, px: 0 }}>
                                        <ListItemText primary={course} primaryTypographyProps={{ variant: 'body2' }} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <List sx={{ padding: 0 }}>
                                {classList2.map(course => (
                                    <ListItem key={course} alignItems="flex-start" sx={{
                                        py: 0.5
                                        , px: 0
                                    }}>
                                        <ListItemText primary={course} primaryTypographyProps={{ variant: 'body2' }} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>




                </Paper>
            </Box>
        </div>
    );

}

export default ResumeView;
