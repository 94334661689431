import AboutView from './components/views/AboutView/AboutView';
import IntroView from './components/views/IntroView/IntroView';
import ResumeView from './components/views/ResumeView/ResumeView';

function App() {

  return (
    <div className="App">
      <div className="stars">
        <div className="twinkling">
          <div className="clouds">
            <IntroView />
            <AboutView />
            <ResumeView />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
