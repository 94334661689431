import Button from '@mui/material/Button';

import './ScrollButtons.css';

const ScrollButtons = () => {
    const scrollToAbout = () => {
        scrollToSection({ id: 'about-view' });
    }

    const scrollToResume = () => {
        scrollToSection({ id: 'resume-view' });
    }

    const scrollToSection = ({ id }) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className="buttons">
            <Button
                onClick={scrollToAbout}
                variant="outlined"
                size="large"
                sx={{
                    color: 'white',
                    borderColor: 'white',
                    '&:hover': {
                        borderColor: 'gray',
                        backgroundColor: '#3a3737',
                    }
                }}
            >
                About
            </Button>
            <Button
                onClick={scrollToResume}
                variant="outlined"
                size="large"
                sx={{
                    color: 'white',
                    borderColor: 'white',
                    '&:hover': {
                        borderColor: 'gray',
                        backgroundColor: '#3a3737',
                    }
                }}
            >
                Resume
            </Button>
        </div>
    );
}

export default ScrollButtons;