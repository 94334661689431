import React from 'react';
import { Paper, Typography, Divider, Box } from '@mui/material';

import './AboutView.css';
import aboutData from './aboutData';

const AboutView = () => {
    return (
        <div id="about-view" className="section">
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <Paper sx={{ maxWidth: 960, width: '100%', p: 3, bgcolor: 'background.paper' }}>
                    <Typography variant="h5" align="center" gutterBottom><strong>About Me</strong></Typography>
                    <Divider sx={{ my: 3 }} />
                    {aboutData.map((paragraph, index) => (
                        <Typography variant="body1" className="paperContent tabbed" key={index} gutterBottom>
                            {paragraph}
                        </Typography>
                    ))}
                    <Typography variant="body1" className="paperContent tabbed" gutterBottom>
                        <i>I also enjoy watching the Tarheels win and playing pickleball.</i>
                    </Typography>
                </Paper>
            </Box>
        </div>
    );
}

export default AboutView;
