const resumeData = {
    skills: {
        languages: ["Java", "Python", "JavaScript", "TypeScript", "C", "Bash", "SQL"],
        frameworks: ["Spring", "FastAPI", "React", "Angular", "Django", "Flask", "Drools"],
        databases: ["MySQL", "PostgreSQL", "MongoDB", "Redis"],
        cloud: ["Azure", "AWS"],
        other: ["Docker", "Kubernetes", "Git", "Networking Protocols", "Linux/Unix", "Wireshark"]
    },
    workExperience: [
        {
            title: "SPEEDI DELIVERY - Staff Software Development Engineer",
            location: "Chapel Hill, NC",
            date: "October 2023 – Present",
            descriptions: [
                "Spearheaded the migration of servers and databases to Microsoft’s Azure Cloud.",
                "Integrated Apple Pay and remote server push notifications into iOS apps.",
                "Developed a full-stack application for user and product sales management.",
                "Managed and mentored a team of interns contributing to the development of a new server.",
            ]
        },
        {
            title: "AMAZON - Software Development Engineer Intern",
            location: "Dallas, TX",
            date: "May 2023 – August 2023",
            descriptions: [
                "Developed and implemented new claim automation solutions in Java and Drools, increasing efficiency and number of automated claims.",
                "Identified and resolved a software bug, handling 3,000 weekly claims, through innovative automation rules.",
                "Spearheaded a project to significantly reduce abusive buyer appeals overturns through automation rules.",
            ]
        },
        {
            title: "SCANBANDZ - Founder and Lead Software Engineer",
            location: "Chapel Hill, NC",
            date: "May 2022 – Present",
            descriptions: [
                "Pioneered a robust and scalable cloud microservice infrastructure on Microsoft Azure.",
                "Seamlessly integrated servers using Spring, Django, and FastAPI, all backed by PostgreSQL databases.",
                "Developed an end-to-end ticketing ecosystem, encompassing event registration, secure payment processing, advanced ticket authentication, and real-time data analysis.",
                "Delivered critical insights to event organizers through detailed data analytics, uncovering attendance metrics, trends, and patterns, thereby driving strategic decision-making.",
            ]
        }
    ],
    openSourceProjects: [
        {
            name: "SOCKS 5 Proxy",
            link: "https://github.com/JoshCap20/simple-socks5",
            description: "A simple, multithreaded, fully compliant SOCKS Protocol Version 5 server implementation."
        },
        {
            name: "iOS Remote Notification Server",
            link: "https://github.com/JoshCap20/PushNotificationServerFramework",
            description: "A framework for managing device tokens and communication with Apple’s Push Notification service."
        },
        {
            name: "Secure Erase and Delete Tool",
            link: "https://github.com/JoshCap20/Secure-Delete",
            description: "Simple deletion script for files and directories that overwrites binary contents with three passes in accordance with most security standards."
        }
    ],
    classList: [
        'COMP 210 - Data Structures and Analysis',
        'COMP 211 - Systems Fundamentals',
        'COMP 283 - Discrete Structures',
        'COMP 301 - Code Organization',
        'COMP 311 - Computer Organization',
        'COMP 426 - Modern Web Programming',
        'COMP 431 - Internet Services and Protocols',
        'COMP 455 - Models of Language and Computation',
        'COMP 488 - Data Science in the Business World',
        'COMP 550 - Algorithms & Analysis',
        'COMP 590 - Foundations of Software Engineering',
        'ECON 400 - Statistics and Econometrics',
        'ECON 410 - Intermediate Microeconomics',
        'ECON 411 - Game Theory',
        'ECON 420 - Intermediate Macroeconomics',
        'ECON 425 - Financial Economics',
        'ECON 520 - Advanced Macroeconomic Theory',
        'ECON 698 - PPE Capstone Seminar',
    ]
};

export default resumeData;
